import { memo, useCallback, useMemo, useState } from 'react';
import { useCallbackObservable, useRetryableObservable } from 'react-use-observable';

import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { IModelStructure } from 'src/interfaces/structure';
import structureService from 'src/services/structure';
import swal from 'sweetalert';

const StructurePage = memo(() => {
  const [search, setSearch] = useState('');
  const [structures, , , retry] = useRetryableObservable(() => structureService.list(search), [search]);

  const filteredStructure = useMemo(
    () => structures?.map(structure => ({ ...structure, id: structure.codEstrutura })) ?? [],
    [structures]
  );

  const onSearch = useCallback(search => setSearch(search), []);
  const [onDelete] = useCallbackObservable(
    (props: IModelStructure) => {
      return structureService.delete(props).pipe(
        tap(
          () => retry(),
          () => swal('Ops!', 'Não foi possível deletar a permissão', 'error')
        )
      );
    },
    [structures]
  );

  const loading = !structures;

  return (
    <Container paper full>
      <Header title='Estruturas' to='/structure/create' showCreateButton onSearch={onSearch} />
      <div>
        <Table
          loading={loading}
          data={filteredStructure}
          onDelete={onDelete}
          columns={[
            {
              Header: 'Código da Estrutura',
              accessor: 'codEstrutura'
            },
            {
              Header: 'Descrição',
              accessor: 'descricao'
            },
            {
              Header: 'Tipo',
              accessor: 'tipo'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default StructurePage;

import { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRetryableObservable } from 'react-use-observable';

import { Button, makeStyles } from '@material-ui/core';
import { map, tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import AutocompleteField from 'src/components/Shared/Fields/Autocomplete';
import DateField from 'src/components/Shared/Fields/Date';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import { IUserUpdateModel } from 'src/interfaces/user';
import historyService from 'src/services/history';
import userService from 'src/services/user';
import swal from 'sweetalert';
import * as yup from 'yup';

const UserDetailsPage = memo(() => {
  const useStyles = makeStyles(() => ({
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const { state } = useLocation<IUserUpdateModel>();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        usuario: yup.string().required('Informe o usuário').nullable(),
        nmUsuario: yup.string().required('Informe o nome do usuário').nullable(),
        dtNascimento: yup.string().required('Informe a data de nascimento').nullable(),
        dsCargo: yup.string().required('Informe o cargo').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: state,
    validationSchema,
    onSubmit: values =>
      userService.update(values).pipe(
        tap(
          () => historyService.goBack(),
          () => swal('Ops!', 'Não foi possível editar este usuário.', 'error')
        )
      )
  });

  const [cargos, , ,] = useRetryableObservable(
    () =>
      userService
        .listUserOccupation()
        .pipe(map(cargos => cargos?.map(cargo => ({ ...cargo, title: cargo.dsCargo })) ?? null)),
    []
  );

  return (
    <Container paper>
      <Header title='Editar Usuário' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <TextField name='usuario' label='Usuário' variant='outlined' formik={formik} />
        <TextField name='nmUsuario' label='Nome do usuário' variant='outlined' formik={formik} />

        <DateField name='dtNascimento' label='Data de nascimento' formik={formik} />
        <AutocompleteField name='dsCargo' label='Cargo' data={cargos ?? []} formik={formik} />

        <div>
          <Button
            to='/user'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default UserDetailsPage;

import { memo } from 'react';

import { Button, makeStyles } from '@material-ui/core';

interface IProps {
  url: string;
  onDeleteImage: () => void;
}

const UploadItem = memo<IProps>(({ url, onDeleteImage }) => {
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: 200
    },
    preview: {
      width: 200,
      height: 200
    }
  }));
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img className={styles.preview} src={url} />
      <Button color='primary' onClick={onDeleteImage}>
        Remover Arquivo
      </Button>
    </div>
  );
});

export default UploadItem;

import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';
import Container from 'src/components/Shared/Container';
import SelectField from 'src/components/Shared/Fields/Select';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import * as yup from 'yup';

const ReplacementCreate = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        idCompany: yup.string().required('Informe a empresa').nullable(),
        idStore: yup.string().required('Informe a loja').nullable(),
        genre: yup.string().required('Informe o gênero').nullable(),
        user: yup.string().required('Informe o usuário').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { name: '' },
    validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values));
    }
  });

  return (
    <Container paper>
      <Header title='Nova Reposição' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <SelectField name='idCompany' label='Empresa' data={[]} formik={formik} />
        <SelectField name='idStore' label='Loja' data={[]} formik={formik} />
        <TextField name='genre' label='Gênero' variant='outlined' formik={formik} />
        <TextField name='user' label='Usuário' variant='outlined' formik={formik} />

        <div>
          <Button
            to='/replacement'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default ReplacementCreate;

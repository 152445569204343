import { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button, FormControl, InputProps, makeStyles } from '@material-ui/core';
import { FormikInstance } from 'src/hooks/useFormikObservable';

import UploadItem from './UploadItem';

interface IProps extends InputProps {
  name: string;
  formik?: FormikInstance;
  label?: string;
  albums?: any[];
  variant?: 'outlined' | 'text' | 'contained';
  accept?: string;
  multiple?: boolean;
}

const UploadField = memo<IProps>(({ name, label, value, variant, accept, formik, multiple }) => {
  const [images, setImages] = useState([]);
  const fieldMeta = formik?.getFieldMeta(name) ?? { touched: false, error: '', value };
  fieldMeta.value = value ?? fieldMeta.value;
  // const disabled = props.disabled || formik?.isSubmitting || (!multiple && [...images, ...albums].length > 0);

  const useStyles = makeStyles(() => ({
    container: {
      margin: '0.6rem 0',
      width: '100%'
    },
    input: {
      display: 'none',
      width: '100%'
    }
  }));
  const styles = useStyles();

  const onDrop = useCallback(
    acceptedFiles => {
      const newImages = images.concat(
        acceptedFiles.map((item: any) => {
          return {
            preview: URL.createObjectURL(item),
            upload: true,
            type: item.type,
            item
          };
        })
      );

      setImages([...newImages]);

      onUpload(newImages);
    },
    [images]
  );

  const onUpload = useCallback(data => formik?.setFieldValue(name, data), []);
  const onDeleteImage = useCallback(
    url => () => setImages(images => images.filter(image => image.preview !== url)),
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept
  });

  return (
    <FormControl className={styles.container}>
      <div {...getRootProps()}>
        <input className={styles.input} multiple={multiple} {...getInputProps()} />
        <label htmlFor={name}>
          <Button variant={variant} color='secondary' component='span'>
            {label}
          </Button>
        </label>
      </div>

      {images.map(image => (
        <UploadItem key={image.preview} url={image.preview} onDeleteImage={onDeleteImage(image.preview)} />
      ))}
    </FormControl>
  );
});

export default UploadField;

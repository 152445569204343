import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';
import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import historyService from 'src/services/history';
import structureService from 'src/services/structure';
import * as yup from 'yup';

const StructureCreate = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        codEstrutura: yup.string().required('Informe o código da estrutura').nullable(),
        descricao: yup.string().required('Informe a descrição').nullable(),
        tipo: yup.string().required('Informe o tipo de estrutura').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { codEstrutura: null, descricao: '', tipo: '' },
    validationSchema,
    onSubmit: values =>
      structureService.create(values).pipe(
        tap(
          () => historyService.goBack(),
          err => {
            console.log(err);
          }
        )
      )
  });

  return (
    <Container paper>
      <Header title='Nova Estrutura' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <TextField type='number' name='codEstrutura' label='Código da Estrutura' formik={formik} />
        <TextField name='descricao' label='Descrição' formik={formik} />
        <TextField name='tipo' label='Tipo da estrutura' formik={formik} />

        <div>
          <Button
            to='/structure'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default StructureCreate;

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  ICreatePermissionResponse,
  IGetAplicationResponse,
  IGetModuleResponse,
  IModelPermission,
  IPermissionList,
  IPermissionListResponse,
  IUpdatePermission
} from 'src/interfaces/permission';

import apiService, { ApiService } from './api';
import moduleService, { ModuleService } from './module';
import storageService, { StorageService } from './storage';

export class PermissionService {
  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private readonly moduleService: ModuleService
  ) {}

  public list(): Observable<IPermissionListResponse[]> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.get('/varejo/permission/', {
          idEmpresa: values?.company,
          idLoja: values?.store,
          usuario: values?.user
        })
      ),
      map((result: IPermissionList) =>
        typeof result?.Status === 'string'
          ? []
          : result?.Status?.map(values => ({
              aplication: values.aplicacao,
              idEmpresa: values.idEmpresa,
              idLoja: values.idLoja,
              module: values.modulo,
              permission: values.permissao,
              user: values.usuario
            }))
      )
    );
  }

  public create(model: IModelPermission): Observable<ICreatePermissionResponse> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.post('/varejo/permission/', {
          usuario: model.user,
          idEmpresa: values?.company,
          idLoja: model.store,
          aplicacao: model.aplication,
          modulo: model.module
        })
      ),
      tap(() => this.moduleService.refresh(true))
    );
  }

  public update(model: IModelPermission): Observable<any> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.put('/varejo/permission/', {
          usuario: model.user,
          idEmpresa: values?.company,
          idLoja: model.store,
          aplicacao: model.aplication,
          modulo: model.module
        })
      ),
      tap(() => this.moduleService.refresh(true))
    );
  }

  public delete(model: IUpdatePermission): Observable<any> {
    return this.apiService
      .delete('/varejo/permission/', {
        usuario: model.user,
        idEmpresa: model.idEmpresa,
        idLoja: model.idLoja,
        aplicacao: model.aplication,
        modulo: model.module
      })
      .pipe(tap(() => this.moduleService.refresh(true)));
  }

  public getAplication(idLoja?: number): Observable<IGetAplicationResponse> {
    if (!idLoja) of([]);

    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.get<IGetAplicationResponse>('/varejo/permission/get_aplicacao/', {
          idEmpresa: values?.company,
          idLoja
        })
      )
    );
  }

  public getModule(idLoja?: number, aplication?: string): Observable<IGetModuleResponse> {
    if (!idLoja || !aplication) of([]);

    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.get<IGetModuleResponse>('/varejo/permission/get_modulo/', {
          idEmpresa: values?.company,
          idLoja,
          aplicacao: aplication
        })
      )
    );
  }
}

const permissionService = new PermissionService(apiService, storageService, moduleService);
export default permissionService;

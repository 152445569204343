import axios, { AxiosError, AxiosInstance, Method } from 'axios';
import https from 'https';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/config';
import { ApiError } from 'src/errors/api';

import tokenService, { TokenService } from './token';

export class ApiService {
  private client = new ReplaySubject<AxiosInstance>(1);
  private token$ = new BehaviorSubject<string | null>('');

  constructor(private tokenService: TokenService) {
    this.createInstance();
    this.tokenService
      .getToken()
      .subscribe(token => this.token$.next(token?.accessToken ?? 'Token 245b8d3fb03f2ca0fd907edb18f6c6a0171b0cf7'));
  }

  public get<T = any>(url: string, params?: any): Observable<T> {
    return this.request('GET', url, params);
  }

  public post<T = any>(url: string, body?: any): Observable<T> {
    return this.request('POST', url, body);
  }

  public put<T = any>(url: string, body: any): Observable<T> {
    return this.request('PUT', url, body);
  }

  public delete<T = any>(url: string, body: any): Observable<T> {
    return this.request('DELETE', url, body);
  }

  private request<T = any>(method: Method, url: string, data: any = null): Observable<T> {
    return this.client.pipe(
      switchMap(connection =>
        connection({
          url,
          method,
          headers: {
            ...connection.defaults.headers,
            Authorization: this.token$.value
          },
          params: method.toUpperCase() === 'GET' || method.toUpperCase() === 'DELETE' ? data : null,
          data: method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT' ? data : null
        })
      ),
      map(response => response?.data?.response ?? response?.data ?? null),
      catchError(err => this.handleError(err))
    );
  }

  private createInstance(): void {
    this.client.next(
      axios.create({
        baseURL: API_ENDPOINT,
        timeout: 300000,
        httpsAgent: new https.Agent({
          rejectUnauthorized: false
        })
      })
    );
  }

  private handleError(err: AxiosError) {
    if (!err.config) {
      return throwError(err);
    }

    return throwError(new ApiError(err.config, err.response, err));
  }
}

const apiService = new ApiService(tokenService);
export default apiService;

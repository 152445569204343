import { memo, useCallback, useMemo, useState } from 'react';
import { useCallbackObservable, useRetryableObservable } from 'react-use-observable';

import { map, tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { useDictionary } from 'src/hooks/useDictionary';
import { IModelAddress } from 'src/interfaces/address';
import addressService from 'src/services/address';
import swal from 'sweetalert';

const AddressPage = memo(() => {
  const [search, setSearch] = useState('');
  const addressDictionary = useDictionary({ type: 'Endereco' });

  const [address, , , retry] = useRetryableObservable(
    () => addressService.list(search).pipe(map(result => (typeof result?.Status === 'string' ? [] : result?.Status))),
    [search]
  );

  const filteredAddress = useMemo(() => address?.map(address => ({ ...address, id: address.seq })) ?? [], [address]);

  const onSearch = useCallback(search => setSearch(search), []);
  const [onDelete] = useCallbackObservable(
    (props: IModelAddress) => {
      return addressService.delete(props).pipe(
        tap(
          () => retry(),
          () => swal('Ops!', 'Não foi possível deletar a permissão', 'error')
        )
      );
    },
    [address]
  );

  const loading = !address;

  return (
    <Container paper full>
      <Header title='Endereços' to='/address/create' showCreateButton onSearch={onSearch} />
      <div>
        <Table
          loading={loading}
          data={filteredAddress}
          onDelete={onDelete}
          columns={[
            {
              Header: addressDictionary.getHeader('codEstrutura'),
              accessor: 'codEstrutura'
            },
            {
              Header: addressDictionary.getHeader('rua'),
              accessor: 'rua'
            },
            {
              Header: addressDictionary.getHeader('coluna'),
              accessor: 'coluna'
            },
            {
              Header: addressDictionary.getHeader('nivel'),
              accessor: 'nivel'
            },
            {
              Header: addressDictionary.getHeader('vao'),
              accessor: 'vao'
            },
            {
              Header: addressDictionary.getHeader('endereco'),
              accessor: 'endereco'
            },
            {
              Header: addressDictionary.getHeader('capacidade'),
              accessor: 'capacidade'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default AddressPage;

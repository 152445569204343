/* eslint-disable @typescript-eslint/ban-types */
import { useCallback, useEffect, useRef } from 'react';
import { useObservable } from 'react-use-observable';

import { FormikConfig, FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik';
import { Observable, of, Subject } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';

export declare type FormikInstance<Values = any> = ReturnType<typeof useFormikObservable> & {
  values: Partial<Values>;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
};

export declare type FormikConfigResolver<Values> = {
  [K in Exclude<keyof FormikConfig<Values>, 'onSubmit' | 'initialValues'>]?: FormikConfig<Values>[K];
};

interface IParams<Values, Result$> extends FormikConfigResolver<Values> {
  initialValues?: Partial<Values>;
  onSubmitWithErrors?: (errors: FormikErrors<Values>, values: Partial<Values>) => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void | Observable<Result$>;
}

export function useFormikObservable<Values = {}, Result$ = any>({
  onSubmit,
  onSubmitWithErrors,
  ...params
}: IParams<Values, Result$>) {
  const promiseRef = useRef<{ promise?: Promise<any> }>({}).current;
  // const handlers = useRef<{ [key: string]: (value: any) => void }>({}).current;
  const submitData = useRef(new Subject<{ model: Partial<Values>; formikHelpers: FormikHelpers<Values> }>()).current;

  useObservable<Result$>(() => {
    return submitData.pipe(
      switchMap(({ model, formikHelpers }) => {
        const result$ = onSubmit(model as Values, formikHelpers);

        const result = (!result$ ? of(null) : result$).pipe(
          catchError(() => of(null)),
          share()
        );

        promiseRef.promise = result.toPromise();

        return result;
      })
    );
  }, []);

  const formik = useFormik<Partial<Values>>({
    initialValues: {},
    ...params,
    onSubmit: (model, formikHelpers) => {
      submitData.next({ model, formikHelpers });
      return new Promise(resolve => setTimeout(() => resolve(promiseRef.promise), 500));
    }
  });

  useEffect(() => {
    if (!formik.submitCount || formik.isValid) return;
    onSubmitWithErrors && onSubmitWithErrors(formik.errors, formik.values);
  }, [formik.submitCount]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [params]
  );

  return {
    ...formik,
    handleSubmit
  };
}

import { memo } from 'react';
import { Router } from 'react-router';

import { CssBaseline, ThemeProvider } from '@material-ui/core';

import getTheme from './assets/theme';
import Routes from './routes';
import historyService from './services/history';

const App = memo(() => {
  const themeProvider = getTheme();

  return (
    <ThemeProvider theme={themeProvider}>
      <CssBaseline />
      <Router history={historyService}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
});

export default App;

import React, { memo, useCallback } from 'react';
import Marquee from 'react-fast-marquee';
import { Link, useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';

import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import ti9 from 'src/assets/ti9-azul.png';
import UserStatus from 'src/components/Shared/UserStatus';
import moduleService from 'src/services/module';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
    // padding: theme.spacing(3)
  },
  bar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    bottom: 0,
    position: 'fixed',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary
  },
  marquee: {
    width: 200,
    color: '#fff'
  },
  ti9logo: {
    width: 80,
    paddingLeft: 5,
    paddingRight: 5
  },
  backgroundLogo: {
    background: 'white'
  },
  projectName: {
    paddingLeft: 20
  }
}));

const AppLayout = memo(({ children }) => {
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(history.location.pathname === '/' ? true : false);
  const onOpen = useCallback(() => setOpen(!open), [open]);

  const [modules] = useObservable(() => {
    return moduleService.observableResfresh().pipe(switchMap(refresh => moduleService.getModules(refresh)));
  }, []);

  useObservable(() => timer(0, 60000).pipe(tap(() => moduleService.refresh(true))), []);

  return (
    <div className={styles.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(styles.appBar, {
          [styles.appBarShift]: open
        })}
      >
        <Toolbar className={styles.bar}>
          <div className={styles.bar}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={onOpen}
              edge='start'
              className={clsx(styles.menuButton, {
                [styles.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className={styles.backgroundLogo}>
              <img src={ti9} alt='Ti9' className={styles.ti9logo} />
            </div>

            <Typography variant='h5' noWrap className={styles.projectName}>
              Varejo
            </Typography>
          </div>
          <UserStatus />
        </Toolbar>
      </AppBar>
      <div
        className={clsx(styles.footer, {
          [styles.appBarShift]: open
        })}
      >
        <Marquee gradient={false} speed={50}>
          <div className={styles.marquee}>
            <h6>Bem vindo ao Ti9 Varejo.</h6>
          </div>
        </Marquee>
      </div>
      <Drawer
        variant='permanent'
        className={clsx(styles.drawer, {
          [styles.drawerOpen]: open,
          [styles.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [styles.drawerOpen]: open,
            [styles.drawerClose]: !open
          })
        }}
      >
        <div className={styles.toolbar}>
          <IconButton onClick={onOpen}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {modules?.map((menu, index) => {
            const { icon, screen } = menu;

            return (
              <ListItem button component={Link} to={`/${screen}` ?? '/'} key={index}>
                <ListItemIcon>
                  {icon?.name ? <Icon fontSize={menu.icon?.size ?? 'default'} /> : icon.component}
                </ListItemIcon>
                <ListItemText primary={menu.title} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <main className={styles.content}>
        <div className={styles.toolbar} />
        {children}
      </main>
    </div>
  );
});

export default AppLayout;

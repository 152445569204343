import { memo } from 'react';

import { makeStyles, Paper } from '@material-ui/core';

import Loading from './Loading';

interface IProps {
  children: React.ReactNode;
  format?: 'grid' | 'flex';
  align?: 'flex-start' | 'flex-end' | 'center';
  justify?: 'flex-start' | 'flex-end' | 'center';
  paper?: boolean;
  full?: boolean;
  elevation?: number;
  loading?: boolean;
}

const Container = memo<IProps>(({ children, format, align, justify, paper, full, elevation, loading, ...props }) => {
  const displayFormat = !format ? 'flex' : format;
  const useStyles = makeStyles(() => ({
    container: {
      height: '100%',
      ...(displayFormat === 'flex' && {
        display: 'flex',
        alignItems: align || 'center',
        justifyContent: justify || 'center'
      })
    },
    paperContainer: {
      width: full ? '80%' : '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    }
  }));

  const styles = useStyles();
  const resultComponent = paper ? (
    <Paper className={styles.paperContainer} elevation={elevation ?? 5}>
      {loading ? <Loading /> : children}
    </Paper>
  ) : loading ? (
    <Loading />
  ) : (
    children
  );

  return (
    <div className={styles.container} {...props}>
      {resultComponent}
    </div>
  );
});

export default Container;

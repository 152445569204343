import { memo, useCallback, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FormikInstance } from 'src/hooks/useFormikObservable';

interface IProps {
  name: string;
  formik: FormikInstance;
  label?: string;
  helpMessage?: string;
  data?: any[];
}

const DateField = memo<IProps>(({ name, label, formik, helpMessage }) => {
  const fieldMeta = formik?.getFieldMeta(name) ?? { touched: false, error: '', value: new Date() };
  const hasError = formik?.submitCount > 0 && !!fieldMeta.error;
  const disabled = formik?.isSubmitting;
  const [focused, setFocused] = useState(false);

  const useStyles = makeStyles(() => ({
    container: {
      margin: '0.6rem 0',
      width: '100%'
    }
  }));
  const styles = useStyles();

  const onChange = useCallback(date => formik?.setFieldValue(name, date), []);
  const onChangeFocus = useCallback(value => () => setFocused(value), []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={name}
        name={name}
        label={label}
        InputLabelProps={{ shrink: focused ? true : !fieldMeta.value ? false : true }}
        onChange={onChange}
        onFocus={onChangeFocus(true)}
        onBlur={onChangeFocus(false)}
        className={styles.container}
        value={fieldMeta.value}
        disabled={disabled}
        error={hasError}
        helperText={hasError ? fieldMeta.error : helpMessage}
        format='dd/MM/yyyy'
        inputVariant='outlined'
      />
    </MuiPickersUtilsProvider>
  );
});

export default DateField;

import { memo } from 'react';
import { Switch } from 'react-router-dom';

import AddressPage from 'src/components/Pages/Address';
import AddressCreate from 'src/components/Pages/Address/Create';
import AddressDetailsPage from 'src/components/Pages/Address/Details';
import BrandPage from 'src/components/Pages/Brand';
import BrandCreate from 'src/components/Pages/Brand/Create';
import ChangePasswordPage from 'src/components/Pages/ChangePassword';
import CompanyPage from 'src/components/Pages/Company';
import CompanyCreate from 'src/components/Pages/Company/Create';
import DefaultPage from 'src/components/Pages/Default';
import GenrePage from 'src/components/Pages/Genre';
import GenreCreate from 'src/components/Pages/Genre/Create';
import ImportPage from 'src/components/Pages/Import';
import LoginPage from 'src/components/Pages/Login';
import NotFoundPage from 'src/components/Pages/NotFound';
import PermissionPage from 'src/components/Pages/Permission';
import PermissionCreate from 'src/components/Pages/Permission/Create';
import ProductPage from 'src/components/Pages/Product';
import ProductCreate from 'src/components/Pages/Product/Create';
import ProductDetailsPage from 'src/components/Pages/Product/Details';
import ReplacementPage from 'src/components/Pages/Replacement';
import ReplacementCreate from 'src/components/Pages/Replacement/Create';
import StorePage from 'src/components/Pages/Store';
import StoreCreate from 'src/components/Pages/Store/Create';
import StoreDetailsPage from 'src/components/Pages/Store/Details';
import StructurePage from 'src/components/Pages/Structure';
import StructureCreate from 'src/components/Pages/Structure/Create';
import StructureDetailsPage from 'src/components/Pages/Structure/Details';
import UserPage from 'src/components/Pages/User';
import UserCreate from 'src/components/Pages/User/Create';
import UserDetailsPage from 'src/components/Pages/User/Details';

import Route from './Route';

const Routes = memo(() => {
  return (
    <Switch>
      <Route exact path='/' component={DefaultPage} headerTitle='Login' isPrivate={true} />
      <Route exact path='/login' component={LoginPage} headerTitle='Login' isPrivate={false} />

      {/* Rotas padrão */}
      <Route exact path='/changePassword' component={ChangePasswordPage} headerTitle='Troca de senha' />
      <Route exact path='/company' component={CompanyPage} headerTitle='Empresas' />
      <Route exact path='/store' component={StorePage} headerTitle='Lojas' />
      <Route exact path='/user' component={UserPage} headerTitle='Usuários' />
      <Route exact path='/permission' component={PermissionPage} headerTitle='Permissões' />
      <Route exact path='/brand' component={BrandPage} headerTitle='Marcas' />
      <Route exact path='/genre' component={GenrePage} headerTitle='Gêneros' />
      <Route exact path='/product' component={ProductPage} headerTitle='Produtos' />
      <Route exact path='/structure' component={StructurePage} headerTitle='Estruturas' />
      <Route exact path='/address' component={AddressPage} headerTitle='Endereços' />
      <Route exact path='/replacement' component={ReplacementPage} headerTitle='Reposições - Gêneros' />
      <Route exact path='/import' component={ImportPage} headerTitle='Importação' />

      {/* Rotas de criação  */}
      <Route exact path='/company/create' component={CompanyCreate} headerTitle='Cadastro de Empresas' />
      <Route exact path='/store/create' component={StoreCreate} headerTitle='Cadastro de Lojas' />
      <Route exact path='/user/create' component={UserCreate} headerTitle='Cadastro de Usuários' />
      <Route exact path='/permission/create' component={PermissionCreate} headerTitle='Cadastro de Permissões' />
      <Route exact path='/brand/create' component={BrandCreate} headerTitle='Cadastro de Marcas' />
      <Route exact path='/genre/create' component={GenreCreate} headerTitle='Cadastro de Gêneros' />
      <Route exact path='/product/create' component={ProductCreate} headerTitle='Cadastro de Produtos' />
      <Route exact path='/structure/create' component={StructureCreate} headerTitle='Cadastro de Estruturas' />
      <Route exact path='/address/create' component={AddressCreate} headerTitle='Cadastro de Endereços' />
      <Route
        exact
        path='/replacement/create'
        component={ReplacementCreate}
        headerTitle='Cadastro de Reposições - Gêneros'
      />

      {/* Rotas de Detalhe */}
      <Route exact path='/store/:id' component={StoreDetailsPage} headerTitle='Editando Loja' />
      <Route exact path='/structure/:id' component={StructureDetailsPage} headerTitle='Editando Estrutura' />
      <Route exact path='/address/:id' component={AddressDetailsPage} headerTitle='Editando Estrutura' />
      <Route exact path='/user/:id' component={UserDetailsPage} headerTitle='Editando Usuário' />
      <Route exact path='/product/:id' component={ProductDetailsPage} headerTitle='Editando Produto' />

      <Route component={NotFoundPage} headerTitle='Página não encontrada' ignoreSigned />
    </Switch>
  );
});

export default Routes;

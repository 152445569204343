import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IStorage } from 'src/interfaces/storage';
import { IStoreCreate, IStoreCreateResponse, IStoreListResponse, IStoreUpdate } from 'src/interfaces/store';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class StoreService {
  private storageValues$ = new BehaviorSubject<IStorage>(null);

  constructor(private apiService: ApiService, private storageService: StorageService) {
    this.storageService.get<IStorage>('authToken').subscribe(values => this.storageValues$.next(values));
  }

  public list(): Observable<IStoreListResponse[]> {
    return this.storageService
      .get('authToken')
      .pipe(switchMap(values => this.apiService.get('/varejo/lista_loja/', { usuario: values?.user })));
  }

  public create(model: IStoreCreate): Observable<IStoreCreateResponse> {
    return this.apiService.post('/varejo/loja/', {
      ...model,
      usuario: this.storageValues$.value.user,
      idEmpresa: this.storageValues$.value?.company,
      flAtivo: model.flAtivo ? 'S' : 'N'
    });
  }

  public update(model: IStoreUpdate): Observable<IStoreCreateResponse> {
    return this.apiService.put(`/varejo/loja/?idLoja=${model.idLoja}`, {
      idEmpresa: this.storageValues$.value?.company,
      nmLoja: model.nmLoja,
      flAtivo: model.flAtivo ? 'S' : 'N'
    });
  }

  public delete(model: IStoreUpdate): Observable<IStoreCreateResponse> {
    return this.apiService.delete('/varejo/loja/', { idLoja: model.idLoja, idEmpresa: model.idEmpresa });
  }
}

const storeService = new StoreService(apiService, storageService);
export default storeService;

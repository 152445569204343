import { memo } from 'react';

import './styles.css';

interface IProps {
  width?: string;
  message?: string;
}

const Loading = memo<IProps>(({ width, message }) => {
  return (
    <div className='showbox'>
      <div className='loader' style={{ width: width ? width : '45px' }}>
        <svg className='circular' viewBox='25 25 50 50'>
          <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2' strokeMiterlimit='10' />
        </svg>
      </div>
      <small>{message ?? 'Carregando...'}</small>
    </div>
  );
});

export default Loading;

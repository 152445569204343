import { Fragment, memo, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useObservable } from 'react-use-observable';

import { Button, makeStyles, Paper } from '@material-ui/core';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import city from 'src/assets/empty-chart.svg';
import Container from 'src/components/Shared/Container';
import SelectField from 'src/components/Shared/Fields/Select';
import TextField from 'src/components/Shared/Fields/Text';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import authService from 'src/services/auth';
import swal from 'sweetalert';
import * as yup from 'yup';

const LoginPage = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 500,
      padding: 16
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    image: {
      height: '50%',
      position: 'absolute',
      bottom: 20,
      right: 20
    },
    imageBackground: {
      top: 0,
      left: 0,
      zIndex: -1,
      height: '100%',
      width: '100%',
      position: 'absolute',
      background: 'linear-gradient(180deg, #1f75b2, #9E95FF)',
      clipPath: 'circle(70% at right 100%)'
    }
  }));
  const styles = useStyles();

  const [stores, setStores] = useState<{ label: string; value: number; company: number }[]>([]);

  const history = useHistory();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        user: yup.string().required('Informe seu usuário').nullable(),
        store: yup.number().required('Selecione uma loja').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { store: null, user: '', password: '', company: null },
    validationSchema,
    onSubmit: values => {
      return authService.login(values).pipe(
        tap(
          response => history.push(response.changePassword ? '/changePassword' : '/'),
          () => swal('Ops!', 'Não foi possível concluir essa ação!', 'error')
        )
      );
    }
  });

  useObservable(() => {
    return of(true).pipe(
      filter(() => formik.values.user.indexOf('.') !== -1),
      switchMap(() => authService.listUserStores(formik.values.user)),
      map(userStores => {
        const newStores = userStores.map(store => ({
          label: store.nmLoja,
          value: store.idLoja,
          company: store.idEmpresa
        }));
        setStores(newStores);
      })
    );
  }, [formik.values.user]);

  useObservable(() => {
    return of(true).pipe(
      filter(() => !!formik.values.store),
      map(() => {
        const store = stores.find(store => store.value === formik.values.store);
        formik.setFieldValue('company', store.company);
      })
    );
  }, [formik.values.store]);

  return (
    <Container justify='flex-start'>
      <div className={styles.content}>
        <Paper elevation={3} className={styles.paperContainer}>
          <h2>Entre com sua conta do Varejo.</h2>
          <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
            <TextField name='user' label='Usuário' formik={formik} />

            {!!stores?.length && (
              <Fragment>
                <SelectField name='store' label='Loja' data={stores} formik={formik} />

                <TextField name='password' label='Senha' type='password' formik={formik} />

                <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
                  Entrar
                </Button>
              </Fragment>
            )}
          </form>
        </Paper>
        <div>
          <img src={city} className={styles.image} />
          <div className={styles.imageBackground} />
        </div>
      </div>
    </Container>
  );
});

export default LoginPage;

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { dateFormat } from 'src/helpers/date';
import { IAuthToken } from 'src/interfaces/token';
import { IUserList, IUserListCargos, IUserModel } from 'src/interfaces/user';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class UserService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public listUserOccupation() {
    return this.apiService
      .get('/varejo/Usuario/1/select_cargo/')
      .pipe(map((result: IUserListCargos) => (typeof result?.Status === 'string' ? [] : result?.Status)));
  }

  public list(search?: string) {
    return this.storageService.get<IAuthToken>('authToken').pipe(
      switchMap(values =>
        this.apiService.get('/varejo/Usuario/', { idEmpresa: values.company, idLoja: values.store, q: search })
      ),
      map((result: IUserList) => (typeof result?.Status === 'string' ? [] : result?.Status))
    );
  }

  public create(model: IUserModel): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.post('/varejo/Usuario/', {
          ...model,
          dtNascimento: dateFormat(model.dtNascimento, 'yyyy-MM-dd'),
          idEmpresa: values?.company,
          idLoja: values?.store
        })
      )
    );
  }

  public update(model: IUserModel): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.put(`/varejo/Usuario/?usuario=${model.usuario}`, {
          idEmpresa: values?.company,
          idLoja: values?.store,
          newUser: model.usuario,
          nmUsuario: model.nmUsuario,
          dtNascimento: dateFormat(model.dtNascimento, 'yyyy-MM-dd'),
          dsCargo: model.dsCargo
        })
      )
    );
  }
}

const userService = new UserService(apiService, storageService);
export default userService;

import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useObservable } from 'react-use-observable';

import { Button, makeStyles } from '@material-ui/core';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import SelectField from 'src/components/Shared/Fields/Select';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import authService from 'src/services/auth';
import historyService from 'src/services/history';
import permissionService from 'src/services/permission';
import swal from 'sweetalert';
import * as yup from 'yup';

const PermissionCreate = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        store: yup.string().required('Informe a loja').nullable(),
        aplication: yup.string().required('Informe a aplicação').nullable(),
        user: yup.string().required('Informe o usuário').nullable(),
        module: yup.string().required('Informe o modulo').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { store: null, aplication: null, user: '', module: '', permission: '' },
    validationSchema,
    onSubmit: values =>
      permissionService.create(values).pipe(
        tap(
          () => historyService.goBack(),
          () => swal('Ops!', 'Não foi possível criar esta permissão', 'error')
        )
      )
  });

  const [stores] = useObservable(
    () =>
      authService.listUserStores().pipe(
        map(stores => {
          const newStoresValues = stores.map(store => ({
            label: store.nmLoja,
            value: store.idLoja,
            company: store.idEmpresa
          }));

          return newStoresValues;
        })
      ),
    []
  );

  const [aplications] = useObservable(
    () =>
      of(true).pipe(
        filter(() => !!formik?.values?.store),
        switchMap(() => permissionService.getAplication(formik?.values?.store)),
        map(result =>
          typeof result?.Status === 'string'
            ? []
            : result?.Status?.map(data => ({
                label: data.aplicacao,
                value: data.aplicacao
              }))
        )
      ),
    [formik?.values?.store]
  );

  const [modules] = useObservable(
    () =>
      of(true).pipe(
        filter(() => !!formik?.values?.store),
        switchMap(() => permissionService.getModule(formik?.values?.store, formik?.values?.aplication)),
        map(result =>
          typeof result?.Status === 'string'
            ? []
            : result?.Status.map((data: { modulo: string }) => ({
                label: data.modulo,
                value: data.modulo
              }))
        )
      ),
    [formik?.values?.store, formik?.values?.aplication]
  );

  return (
    <Container paper>
      <Header title='Nova Permissão' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <SelectField name='store' label='Loja' data={stores} formik={formik} />
        <SelectField name='aplication' label='Aplicações' data={aplications} formik={formik} />
        <TextField name='user' label='Usuário' formik={formik} />
        <SelectField name='module' label='Módulo' data={modules} formik={formik} />

        <div>
          <Button
            to='/permission'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default PermissionCreate;

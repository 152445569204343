import { memo, useMemo } from 'react';

import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select, SelectProps } from '@material-ui/core';
import { FormikInstance } from 'src/hooks/useFormikObservable';

interface IProps extends SelectProps {
  name: string;
  formik?: FormikInstance;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  helpMessage?: string;
  data?: any[];
}

const SelectField = memo<IProps>(({ name, value, formik, label, variant, data, helpMessage, ...props }) => {
  const fieldMeta = formik?.getFieldMeta(name) ?? { touched: false, error: '', value };
  fieldMeta.value = value ?? fieldMeta.value;
  const hasError = formik?.submitCount > 0 && !!fieldMeta.error;
  const disabled = props.disabled || formik?.isSubmitting;

  const useStyles = makeStyles(() => ({
    container: {
      margin: '0.6rem 0',
      width: '100%'
    }
  }));
  const styles = useStyles();

  const filteredData = useMemo(() => {
    if (data?.length === 0) data.push({ label: '', value: '' });
    return data;
  }, [data]);

  return (
    <FormControl variant={variant ?? 'outlined'} className={styles.container} error={hasError}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        {...props}
        disabled={disabled}
        name={name}
        label={label}
        defaultValue=' '
        value={fieldMeta?.value ?? ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {filteredData?.map((value, index) => (
          <MenuItem key={index} value={value.value}>
            {value.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{hasError ? fieldMeta.error : helpMessage}</FormHelperText>
    </FormControl>
  );
});

export default SelectField;

import { memo } from 'react';

import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';

const BrandPage = memo(() => {
  const data: any[] = [];

  return (
    <Container paper full>
      <Header title='Marcas' to='/brand/create' showCreateButton />
      <div>
        <Table
          data={data}
          columns={[
            {
              Header: 'Titulo',
              accessor: 'title'
            },
            {
              Header: 'Data de Criação',
              accessor: 'createdAt'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default BrandPage;

import { memo } from 'react';

import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';

const ReplacementPage = memo(() => {
  const data = [
    { title: 'teste1', createdAt: 'Dez/2020' },
    { title: 'teste', createdAt: 'Dez/2020' },
    { title: 'teste2', createdAt: 'Dez/2020' },
    { title: 'teste3', createdAt: 'Dez/2020' },
    { title: 'teste4', createdAt: 'Dez/2020' },
    { title: 'teste5', createdAt: 'Dez/2020' },
    { title: 'teste6', createdAt: 'Dez/2020' },
    { title: 'teste7', createdAt: 'Dez/2020' },
    { title: 'teste8', createdAt: 'Dez/2020' },
    { title: 'teste9', createdAt: 'Dez/2020' },
    { title: 'teste0', createdAt: 'Dez/2020' },
    { title: 'teste11', createdAt: 'Dez/2020' },
    { title: 'teste12', createdAt: 'Dez/2020' },
    { title: 'teste13', createdAt: 'Dez/2020' },
    { title: 'teste14', createdAt: 'Dez/2020' },
    { title: 'teste15', createdAt: 'Dez/2020' },
    { title: 'teste17', createdAt: 'Dez/2020' },
    { title: 'teste16', createdAt: 'Dez/2020' },
    { title: 'teste19', createdAt: 'Desssz/2020' },
    { title: 'teste20', createdAt: 'Dez/2020' }
  ];

  return (
    <Container paper full>
      <Header title='Reposições - Gêneros' to='/replacement/create' showCreateButton />
      <div>
        <Table
          data={data}
          columns={[
            {
              Header: 'Titulo',
              accessor: 'title'
            },
            {
              Header: 'Data de Criação',
              accessor: 'createdAt'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default ReplacementPage;

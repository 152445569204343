import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';
import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import historyService from 'src/services/history';
import productService from 'src/services/product';
import swal from 'sweetalert';
import * as yup from 'yup';

const ProductCreate = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        codigoBarras: yup.string().required('Informe o código de barras').nullable(),
        descricaoProduto: yup.string().required('Informe a descrição').nullable(),
        prazoValidade: yup.string().required('Informe a validade').nullable(),
        mercN1: yup.string().required('Informe o mercadológico').nullable(),
        mercN2: yup.string().required('Informe o mercadológico').nullable(),
        mercN3: yup.string().required('Informe o mercadológico').nullable(),
        mercN4: yup.string().required('Informe o mercadológico').nullable(),
        estoqueMin: yup.string().required('Informe o estoque mínimo').nullable(),
        usuarioAlteracao: yup.string().required('Informe o usuário responsável').nullable(),
        custo: yup.string().required('Informe o custo').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: {
      codigoBarras: '',
      descricaoProduto: '',
      prazoValidade: '',
      mercN1: '',
      mercN2: '',
      mercN3: '',
      mercN4: '',
      estoqueMin: '',
      usuarioAlteracao: '',
      custo: ''
    },
    validationSchema,
    onSubmit: values =>
      productService.create(values).pipe(
        tap(
          () => historyService.goBack(),
          () => swal('Ops!', 'Não foi possível criar este produto', 'error')
        )
      )
  });

  return (
    <Container paper>
      <Header title='Novo Produto' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <TextField name='codigoBarras' label='Código de barras' formik={formik} />
        <TextField name='descricaoProduto' label='Descrição do produto' formik={formik} />
        <TextField name='prazoValidade' label='Prazo de validade' formik={formik} />
        <TextField name='mercN1' label='Mercadológico 1' formik={formik} />
        <TextField name='mercN2' label='Mercadológico 2' formik={formik} />
        <TextField name='mercN3' label='Mercadológico 3' formik={formik} />
        <TextField name='mercN4' label='Mercadológico 4' formik={formik} />
        <TextField name='estoqueMin' label='Estoque mínimo' formik={formik} />
        <TextField name='usuarioAlteracao' label='Usuário responsável' formik={formik} />
        <TextField name='custo' label='Custo' formik={formik} />

        <div>
          <Button
            to='/product'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default ProductCreate;

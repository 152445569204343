import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IGenreList, IGenreListResponse, IGenreModel } from 'src/interfaces/genre';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class GenreService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public list(search: string): Observable<IGenreListResponse[]> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.get('/varejo/Repos_Genero/', {
          q: search,
          idEmpresa: values?.company,
          idLoja: values?.store
        })
      ),
      map((result: IGenreList) => (typeof result?.Status === 'string' ? [] : result?.Status))
    );
  }

  public create(model: IGenreModel): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.post('/varejo/Repos_Genero/', {
          ...model,
          idEmpresa: values?.company,
          idLoja: values?.store,
          usuario: values?.user
        })
      )
    );
  }

  public delete(model: IGenreModel): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.delete('/varejo/Repos_Genero/', {
          genero: model.genero,
          idEmpresa: values?.company,
          idLoja: values?.store,
          usuario: values?.user
        })
      )
    );
  }
}

const genreService = new GenreService(apiService, storageService);
export default genreService;

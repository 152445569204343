import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IListAddressResponse, IModelAddress } from 'src/interfaces/address';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class AddressService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public list(search: string): Observable<IListAddressResponse> {
    return this.storageService
      .get('authToken')
      .pipe(
        switchMap(values =>
          this.apiService.get('/varejo/Endereco/', { q: search, idEmpresa: values?.company, idLoja: values?.store })
        )
      );
  }

  public create(model: IModelAddress): Observable<void> {
    return this.storageService
      .get('authToken')
      .pipe(
        switchMap(values =>
          this.apiService.post('/varejo/Endereco/', { ...model, idEmpresa: values?.company, idLoja: values?.store })
        )
      );
  }

  public update(model: IModelAddress): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.put(`/varejo/Endereco/?seq=${model?.seq}`, {
          ...model,
          idEmpresa: values?.company,
          idLoja: values?.store
        })
      )
    );
  }

  public delete(model: IModelAddress): Observable<any> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.delete('/varejo/Endereco/', {
          seq: model.seq,
          idEmpresa: values?.company,
          idLoja: values?.store,
          codEstrutura: model.codEstrutura
        })
      )
    );
  }
}

const addressService = new AddressService(apiService, storageService);
export default addressService;

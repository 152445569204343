import { memo, useCallback, useMemo, useState } from 'react';
import { useCallbackObservable, useRetryableObservable } from 'react-use-observable';

import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { IGenreModel } from 'src/interfaces/genre';
import genreService from 'src/services/genre';
import swal from 'sweetalert';

const GenrePage = memo(() => {
  const [search, setSearch] = useState('');
  const [genres, , , retry] = useRetryableObservable(() => genreService.list(search), [search]);

  const filteredGenres = useMemo(() => genres?.map(genre => ({ ...genre, id: genre.genero })) ?? [], [genres]);

  const onSearch = useCallback(search => setSearch(search), []);

  const [onDelete] = useCallbackObservable(
    (props: IGenreModel) => {
      return genreService.delete(props).pipe(
        tap(
          () => retry(),
          () => swal('Ops!', 'Não foi possível deletar o gênero', 'error')
        )
      );
    },
    [genres]
  );

  const loading = !genres;

  return (
    <Container paper full>
      <Header title='Gêneros' to='/genre/create' showCreateButton onSearch={onSearch} />
      <div>
        <Table
          data={filteredGenres}
          loading={loading}
          notClickable
          onDelete={onDelete}
          columns={[
            {
              Header: 'Gênero',
              accessor: 'genero'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default GenrePage;

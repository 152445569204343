import { memo } from 'react';
import { Link } from 'react-router-dom';

import { Fab, makeStyles } from '@material-ui/core';

interface IProps {
  children: React.ReactNode;
  to?: string;
  label?: string;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
}

const FabButtonLink = memo<IProps>(({ to, label, color, children, ...props }) => {
  const useStyles = makeStyles({
    iconContainer: {
      // margin: 10
      // position: 'absolute',
      // right: '13%'
    }
  });
  const styles = useStyles();

  return (
    <div className={styles.iconContainer}>
      <Fab to={to} aria-label={label} color={color ?? 'primary'} component={Link} {...props}>
        {children}
      </Fab>
    </div>
  );
});

export default FabButtonLink;

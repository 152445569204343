import { memo } from 'react';

import { FormControl, InputProps, makeStyles } from '@material-ui/core';
import { TextField as Text } from '@material-ui/core';
import { FormikInstance } from 'src/hooks/useFormikObservable';

interface IProps extends InputProps {
  name: string;
  formik?: FormikInstance;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  helpMessage?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    margin: '0.6rem 0',
    width: '100%'
  }
}));

const TextField = memo<IProps>(({ name, type, label, value, variant, formik, helpMessage, ...props }) => {
  const fieldMeta = formik?.getFieldMeta(name) ?? { touched: false, error: '', value };
  fieldMeta.value = value ?? fieldMeta.value;
  const hasError = formik?.submitCount > 0 && !!fieldMeta.error;

  const disabled = props.disabled || formik?.isSubmitting;

  const styles = useStyles();

  return (
    <FormControl className={styles.container}>
      <Text
        inputProps={{ maxLenght: 2 }}
        fullWidth
        type={type ?? 'default'}
        id={name}
        name={name}
        label={label}
        value={fieldMeta.value}
        variant={variant ?? 'outlined'}
        disabled={disabled}
        error={hasError}
        helperText={hasError ? fieldMeta.error : helpMessage}
        // onBlur={formik?.handleBlur}
        onChange={formik?.handleChange}
      />
    </FormControl>
  );
});

export default TextField;

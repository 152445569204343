import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ICompanyCreate, ICompanyCreateResponse, ICompanyListResponse } from 'src/interfaces/company';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class CompanyService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public list(): Observable<ICompanyListResponse> {
    return this.storageService
      .get('authToken')
      .pipe(switchMap(values => this.apiService.get('/varejo/empresa/', { usuario: values?.user })));
  }

  public create(model: ICompanyCreate): Observable<ICompanyCreateResponse> {
    const data = new FormData();
    if (model?.image[0].item) {
      data.append('logo', model.image[0].item);
    }
    data.set('flAtivo', model.flAtivo ? 'S' : 'N');
    data.set('nmFantasia', model.nmFantasia);
    data.set('nmRazao', model.nmRazao);

    return this.apiService.post('/varejo/empresa/', data);
  }
}

const companyService = new CompanyService(apiService, storageService);
export default companyService;

import { memo, useCallback } from 'react';
import { useHistory } from 'react-router';

import { Button } from '@material-ui/core';
import './styles.css';
import Container from 'src/components/Shared/Container';

const NotFoundPage = memo(() => {
  const history = useHistory();
  const onGoBack = useCallback(() => history.goBack(), []);

  return (
    <Container>
      <div id='page-404'>
        <h1 id='title-404'>Ops!</h1>

        <h3 id='sub-title-404'>Não conseguimos encontrar a sua página.</h3>
        <h6 id='between-the-lines'>
          Provavelmente ela esta em desenvolvimento, volte mais tarde.
          <br />
          Atenciosamente, Varejo Ti9 ❤.
        </h6>

        <Button color='primary' variant='contained' onClick={onGoBack}>
          Voltar a navegação
        </Button>
      </div>
    </Container>
  );
});

export default NotFoundPage;

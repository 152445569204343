import { memo, useCallback } from 'react';

import { InputProps, makeStyles, TextField as Text } from '@material-ui/core';

interface IProps extends InputProps {
  label?: string;
  helperText?: string;
  onSearch: (search: string) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    paddingRight: 10,
    margin: '0.6rem 0',
    width: '100%'
  }
}));

const SearchField = memo<IProps>(({ label, helperText, onSearch }) => {
  const onChange = useCallback(e => onSearch(e.target.value), []);

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Text
        type='default'
        label={label ?? 'Campo de pesquisa'}
        variant='outlined'
        helperText={helperText ?? ''}
        onChange={onChange}
      />
    </div>
  );
});

export default SearchField;

import addDaysFNS from 'date-fns/addDays';
import dateFnsFormat from 'date-fns/format';
import dateFnsIsValid from 'date-fns/isValid';
import locale from 'date-fns/locale/pt-BR';
import dateFnsParse from 'date-fns/parse';

export function dateParse(value: any, format: string = null): Date {
  if (!value) return value;
  if (typeof value !== 'string') return value;

  value = value.replace(/\+.+/gi, '').replace(/\..+$/gi, '');
  const date = !format ? new Date(value) : dateFnsParse(value, format, new Date(), { locale });
  if (!dateFnsIsValid(date)) return value;

  return date;
}

export function dateFormat(date: any, format = 'dd/MM/yyyy'): string {
  if (!date) return '';

  if (!(date instanceof Date)) {
    date = dateParse(date);
  }

  if (!(date instanceof Date)) {
    return '';
  }

  return dateFnsFormat(date, format, { locale });
}

export function removeTime(date: Date): Date {
  return dateParse(dateFormat(date, 'yyyy-MM-dd'), 'yyyy-MM-dd');
}

export function addDays(date: any, days: number, format = 'dd/MM/yyyy'): string {
  if (!date) return '';

  if (!(date instanceof Date)) {
    date = dateParse(date);
  }

  if (!(date instanceof Date)) {
    return '';
  }

  return dateFnsFormat(addDaysFNS(date, days), format);
}

export function formatBirthday(value: Date): string {
  if (!value || !(value instanceof Date) || isNaN(value.getTime())) return;

  const format = value.getFullYear() === 1900 ? "dd 'de' MMMM" : "dd 'de' MMMM 'de' yyyy";
  return dateFormat(value, format);
}

import { useMemo, useState } from 'react';

import { createMuiTheme, Theme } from '@material-ui/core';

const getTheme = (): Theme => {
  const [prefersDarkMode] = useState(false);

  const theme = useMemo(
    () =>
      createMuiTheme({
        overrides: {
          MuiCssBaseline: {
            '@global': {
              'html': {
                height: '100%',
                margin: 0,
                padding: 0,
                outline: 'none',
                WebkitFontSmoothing: 'auto',
                fontFamily: 'Roboto'
              },
              'body': {
                height: '100%'
              },
              '#root': {
                height: '100%'
              }
            }
          }
        },
        palette: {
          primary: {
            main: '#1f75b2'
          },
          type: prefersDarkMode ? 'dark' : 'light'
        }
      }),
    []
  );

  return theme;
};

export default getTheme;

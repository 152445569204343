import { memo } from 'react';

import { makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import FabButtonLink from './FabButtonLink';
import SearchField from './Fields/Search';

interface IProps {
  title: string;
  showCreateButton?: boolean;
  to?: string;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  onSearch?: (search: string) => void;
}

const Header = memo<IProps>(({ title, to, color, showCreateButton, onSearch }) => {
  const useStyles = makeStyles({
    headerContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 20
    },
    title: {
      fontSize: 26
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  });
  const styles = useStyles();

  return (
    <div className={styles.headerContainer}>
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.rightContainer}>
        {!!onSearch && <SearchField onSearch={onSearch} />}
        {showCreateButton && (
          <FabButtonLink color={color ?? 'primary'} to={to ?? '/'}>
            <Add />
          </FabButtonLink>
        )}
      </div>
    </div>
  );
});

export default Header;

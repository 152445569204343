import { memo, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Button, makeStyles, Paper } from '@material-ui/core';
import { tap } from 'rxjs/operators';
import profile from 'src/assets/profile.svg';
import Container from 'src/components/Shared/Container';
import TextField from 'src/components/Shared/Fields/Text';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import authService from 'src/services/auth';
import swal from 'sweetalert';
import * as yup from 'yup';

const ChangePasswordPage = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    paperContainer: {
      position: 'fixed',
      right: 200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 500,
      padding: 16
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    image: {
      height: '50%',
      position: 'absolute',
      bottom: 30,
      left: 50
    },
    imageBackground: {
      top: 0,
      left: 0,
      zIndex: -1,
      height: '100%',
      width: '100%',
      position: 'absolute',
      background: 'linear-gradient(180deg, #1f75b2, #9E95FF)',
      clipPath: 'circle(70% at left 100%)'
    }
  }));
  const styles = useStyles();

  const history = useHistory();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        newPassword: yup.string().required('Informe sua nova senha').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { newPassword: '' },
    validationSchema,
    onSubmit: values =>
      authService.changePassword(values).pipe(
        tap(
          () => history.push('/'),
          () => swal('Ops!', 'Não foi possível alterar a sua senha, tente novamente.', 'error')
        )
      )
  });

  return (
    <Container justify='flex-start'>
      <div className={styles.content}>
        <div>
          <img src={profile} className={styles.image} />
          <div className={styles.imageBackground} />
        </div>
        <Paper elevation={3} className={styles.paperContainer}>
          <h2>Altere sua senha de acesso.</h2>
          <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
            <TextField name='newPassword' label='Nova Senha' type='password' formik={formik} />
            <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
              Trocar minha senha
            </Button>
          </form>
        </Paper>
      </div>
    </Container>
  );
});

export default ChangePasswordPage;

import { memo, useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import SelectField from 'src/components/Shared/Fields/Select';
import Header from 'src/components/Shared/Header';
import Loading from 'src/components/Shared/Loading';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import importService from 'src/services/import';
import swal from 'sweetalert';
import * as yup from 'yup';

const ImportPage = memo(() => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        file: yup.array().required('Arraste ou coloque um arquivo').nullable(),
        type: yup.string().required('Informe o tipo de importação').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { file: null, type: '' },
    validationSchema,
    onSubmit: (values, { setSubmitting }) =>
      importService.uploadFile(values).pipe(
        tap(
          result =>
            swal(
              'Oba!',
              `${
                result.Status_do_upload.toLowerCase() === 'sucesso'
                  ? 'Arquivo importado com sucesso!\n\n'
                  : 'Falha ao importar arquivo!\n\n'
              }` +
                `Número de não importados: ${result?.Status_Importacao?.Número_de_NÃO_importados}\n` +
                `Número de importados: ${result?.Status_Importacao?.Número_importados}`,
              result.Status_do_upload.toLowerCase() === 'sucesso' ? 'success' : 'error'
            ),
          () => swal('Ops!', 'Não foi possível importar este arquivo!', 'error')
        ),
        tap(() => setSubmitting(false))
      )
  });

  const useStyles = makeStyles(() => ({
    upload: {
      width: 400,
      height: 200,
      border: '1px dashed #000',
      display: 'flex',
      justifyContent: 'center',
      aligntems: 'center',
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 'bold',
      padding: 20,
      textAlign: 'center'
    },
    formContainer: {
      opacity: formik?.isSubmitting ? 0.1 : 1,
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    },
    buttonContainer: {
      paddingTop: 10
    },
    imageContainer: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column'
    },
    imageName: {
      paddingBottom: 5
    },
    loadingContainer: {
      position: 'absolute'
    }
  }));
  const styles = useStyles();

  const [images, setImages] = useState([]);

  const onDrop = useCallback(
    acceptedFiles => {
      const newImages = images.concat(
        acceptedFiles.map((item: any) => {
          return {
            preview: URL.createObjectURL(item),
            upload: true,
            type: item.type,
            name: item.name,
            item
          };
        })
      );

      setImages([...newImages]);
      onUpload(newImages);
    },
    [images]
  );

  const onUpload = useCallback(data => formik?.setFieldValue('file', data), []);

  return (
    <Container paper full>
      <div className={styles.loadingContainer}>{formik?.isSubmitting && <Loading />}</div>

      <Header title='Importação' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <SelectField
          name='type'
          label='Tipo'
          data={[
            { label: 'MVE', value: 'MVE' },
            { label: 'PRD', value: 'PRD' }
          ]}
          formik={formik}
        />
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) =>
            images?.length <= 0 ? (
              <div className={styles.upload} {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Arraste o arquivo ou clique para selecionar</p>
              </div>
            ) : (
              <Paper className={styles.imageContainer} elevation={1}>
                {images.map(image => (
                  <strong className={styles.imageName} key={image.preview}>
                    {image.name}
                  </strong>
                ))}
              </Paper>
            )
          }
        </Dropzone>

        <div className={styles.buttonContainer}>
          <Button
            to='/company'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default ImportPage;

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IProductList, IProductListResponse, IProductModel, IProductModel2 } from 'src/interfaces/product';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class ProductService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public list(search: string): Observable<IProductListResponse[]> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.get('/varejo/Produto/', { q: search, idEmpresa: values?.company, idLoja: values?.store })
      ),
      map((result: IProductList) => (typeof result?.Status === 'string' ? [] : result?.Status))
    );
  }

  public create(model: IProductModel): Observable<void> {
    return this.storageService
      .get('authToken')
      .pipe(
        switchMap(values =>
          this.apiService.post('/varejo/Produto/', { ...model, idEmpresa: values?.company, idLoja: values?.store })
        )
      );
  }

  public update(model: IProductModel2): Observable<void> {
    console.log(model);
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.put('/varejo/Produto/', {
          ...model,
          idEmpresa: values?.company,
          idLoja: values?.store,
          codigoBarras: model?.codBarras,
          dataHora: model?.dataHoraAlteracao,
          usuarioAlteracao: model?.usarioAlteracao
        })
      )
    );
  }

  public delete(model: { idProduto: number }): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.delete('/varejo/Produto/', {
          idProduto: model.idProduto,
          idEmpresa: values?.company,
          idLoja: values?.store
        })
      )
    );
  }
}

const productService = new ProductService(apiService, storageService);
export default productService;

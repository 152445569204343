import {
  Business,
  Store,
  PermIdentity,
  BrandingWatermark,
  Wc,
  ShoppingCart,
  Apartment,
  Home,
  TransferWithinAStation,
  ImportantDevices,
  PersonAdd
} from '@material-ui/icons';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IModule } from 'src/interfaces/module';
import { IPermissionList, IPermissionListResponse } from 'src/interfaces/permission';
import { IAuthToken } from 'src/interfaces/token';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class ModuleService {
  private modules$ = new BehaviorSubject<IModule[]>([]);
  private refresh$ = new BehaviorSubject<boolean>(true);

  private menus: IModule[] = [
    { id: 'EMPRESA', title: 'Empresas', icon: { component: <Business /> }, screen: 'company' },
    { id: 'LOJA', title: 'Lojas', icon: { component: <Store /> }, screen: 'store' },
    { id: 'USUARIO', title: 'Usuários', icon: { component: <PersonAdd /> }, screen: 'user' },
    { id: 'PERMISSAO', title: 'Permissões', icon: { component: <PermIdentity /> }, screen: 'permission' },
    { id: 'MARCAS', title: 'Marcas', icon: { component: <BrandingWatermark /> }, screen: 'brand' },
    { id: 'GENERO', title: 'Gêneros', icon: { component: <Wc /> }, screen: 'genre' },
    { id: 'PRODUTO', title: 'Produtos', icon: { component: <ShoppingCart /> }, screen: 'product' },
    { id: 'ARMAZEM', title: 'Estrutura', icon: { component: <Apartment /> }, screen: 'structure' },
    { id: 'ENDERECO', title: 'Endereço', icon: { component: <Home /> }, screen: 'address' },
    {
      id: 'REPOSICOES',
      title: 'Reposições - Gêneros',
      icon: { component: <TransferWithinAStation /> },
      screen: 'replacement'
    },
    {
      id: 'IMPORTACAO',
      title: 'Importação',
      icon: { component: <ImportantDevices /> },
      screen: 'import',
      appear: true
    }
  ];

  constructor(private apiService: ApiService, private storageService: StorageService) {
    this.refresh(true);
  }

  public listUserModules(): Observable<IPermissionListResponse[]> {
    return this.storageService.get<IAuthToken>('authToken').pipe(
      switchMap(values =>
        combineLatest([
          this.apiService.get<IPermissionList>('/varejo/permission/', {
            idEmpresa: values?.company,
            idLoja: values?.store,
            usuario: values?.user
          }),
          this.storageService.get<IAuthToken>('authToken')
        ])
      ),
      map(([result, values]) => {
        console.log(values?.user);
        return typeof result?.Status === 'string'
          ? []
          : result?.Status?.filter(res => res.usuario === values.user)?.map(res => ({
              aplication: res.aplicacao,
              idEmpresa: res.idEmpresa,
              idLoja: res.idLoja,
              module: res.modulo,
              permission: res.permissao,
              user: res.usuario
            }));
      })
    );
  }

  public refresh(value: boolean) {
    return this.refresh$.next(value);
  }

  public observableResfresh(): Observable<boolean> {
    return this.refresh$.asObservable();
  }

  public getModules(refresh: boolean): Observable<IModule[]> {
    return this.listUserModules().pipe(
      map(values => {
        if (refresh) {
          const permissoes = values?.map(perm => perm?.aplication);

          this.modules$.next(this.menus.filter(menu => permissoes?.indexOf(menu.id) !== -1 || menu.appear));
          this.refresh(false);
        }

        return this.modules$.value;
      })
    );
  }
}

const moduleService = new ModuleService(apiService, storageService);
export default moduleService;

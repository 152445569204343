import { BehaviorSubject, Observable, of } from 'rxjs';
import { ILoginParams } from 'src/interfaces/auth';
import { IAuthToken, ISetToken } from 'src/interfaces/token';

import storageService, { StorageService } from './storage';

export class TokenService {
  private authToken$ = new BehaviorSubject<IAuthToken>(null);

  constructor(private storageService: StorageService) {
    this.storageService.get('authToken').subscribe(tokens => this.authToken$.next(tokens));
  }

  public setToken(token: ISetToken, data: ILoginParams) {
    this.storageService
      .set('authToken', {
        accessToken: token.token,
        permissoes: token?.permissoes,
        user: data?.user,
        company: data?.company,
        store: data?.store,
        dictionary: token?.dictDados,
        changePassword: (token?.Change_Passord || token?.Change_Password) ?? false
      })
      .subscribe(tokens => this.authToken$.next(tokens));

    return of(true);
  }

  public getToken(): Observable<IAuthToken> {
    return this.authToken$.asObservable();
  }

  public removeToken() {
    return this.storageService.remove('authToken').subscribe(() => this.authToken$.next(null));
  }
}

const tokenService = new TokenService(storageService);
export default tokenService;

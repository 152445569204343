import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';
import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import genreService from 'src/services/genre';
import historyService from 'src/services/history';
import swal from 'sweetalert';
import * as yup from 'yup';

const GenreCreate = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        genero: yup.string().required('Informe o gênero').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: { genero: '' },
    validationSchema,
    onSubmit: values =>
      genreService.create(values).pipe(
        tap(
          () => historyService.goBack(),
          () => swal('Ops!', 'Não foi possível criar este gênero', 'error')
        )
      )
  });

  return (
    <Container paper>
      <Header title='Novo Gênero' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <TextField name='genero' label='Gênero' formik={formik} />

        <div>
          <Button
            to='/genre'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default GenreCreate;

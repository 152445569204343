import { Fragment, memo, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';

import { Button, makeStyles, Table as MTable, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core/';
import { ArrowLeft, ArrowRight, FirstPage, LastPage, DeleteForever } from '@material-ui/icons/';

import ErrorMessage from './ErrorMessage';
import Loading from './Loading';

interface IProps {
  columns: any;
  data: any;
  startWith?: number;
  loading?: boolean;
  error?: boolean;
  perPage?: number;
  notClickable?: boolean;
  onRetry?: () => void;
  onDelete?: (props: any) => void;
}

const Table = memo<IProps>(({ columns, data, loading, error, perPage, notClickable, onRetry, onDelete }) => {
  const useStyles = makeStyles(() => ({
    table: {
      width: '100%'
    },
    pagination: {
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    buttons: {
      paddingBottom: 10
    },
    button: { margin: 2 },
    row: {
      '&:hover': {
        backgroundColor: '#FFFFE9',
        transition: 'all 400ms'
      }
    },
    cell: {
      textDecoration: 'none'
    },
    tableHeader: {
      fontWeight: 'bold',
      fontSize: 16
    }
  }));
  const styles = useStyles();

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data
    },
    usePagination
  );

  const history = useHistory();
  const path = history.location?.pathname;

  const onGoToPage = useCallback(page => () => gotoPage(page), [path]);
  const onGoToNext = useCallback(() => nextPage(), [path]);
  const onGoToPrevious = useCallback(() => previousPage(), [path]);
  const onDeleteRow = useCallback(
    (props: any) => () => {
      console.log(props);
      return onDelete(props);
    },
    [path]
  );

  useEffect(() => {
    setPageSize(perPage ?? 6);
  }, [perPage]);

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} onClick={onRetry} />;

  return (
    <Fragment>
      <MTable className={styles.table} {...getTableProps()}>
        <TableHead>
          {headerGroups?.map((headerGroup: any, index: number) => (
            <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column: any, index: number) => (
                <TableCell key={index} {...column.getHeaderProps()} className={styles.tableHeader}>
                  {column.render('Header')}
                </TableCell>
              ))}

              {!!onDelete && <TableCell className={styles.cell} />}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page?.map((row: any, index: number) => {
            prepareRow(row);
            return (
              <TableRow key={index} {...row.getRowProps()} className={styles.row}>
                {row?.cells?.map((cell: any, cellIndex: number) =>
                  notClickable ? (
                    <TableCell key={cellIndex} {...cell.getCellProps()} className={styles.cell}>
                      {cell.render('Cell')}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={cellIndex}
                      {...cell.getCellProps()}
                      className={styles.cell}
                      component={Link}
                      to={{ pathname: `${path}/${row?.original?.id}`, state: row.original }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  )
                )}

                {!!onDelete && (
                  <TableCell className={styles.cell} align='right'>
                    <Button color='secondary' variant='text' onClick={onDeleteRow(row.original)}>
                      <DeleteForever />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </MTable>
      <div className={styles.pagination}>
        <div className={styles.buttons}>
          <Button
            color='primary'
            variant='contained'
            onClick={onGoToPage(0)}
            disabled={!canPreviousPage}
            className={styles.button}
          >
            <FirstPage />
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={onGoToPrevious}
            disabled={!canPreviousPage}
            className={styles.button}
          >
            <ArrowLeft />
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={onGoToNext}
            disabled={!canNextPage}
            className={styles.button}
          >
            <ArrowRight />
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={onGoToPage(pageCount - 1)}
            disabled={!canNextPage}
            className={styles.button}
          >
            <LastPage />
          </Button>
        </div>
        <span>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        {/* <span>
      | Go to page:{' '}
      <input
        type='number'
        defaultValue={pageIndex + 1}
        onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
        style={{ width: '100px' }}
      />
    </span>{' '}
    <select
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          Show {pageSize}
        </option>
      ))}
    </select> */}
      </div>
    </Fragment>
  );
});

export default Table;

import { memo, useCallback, useMemo, useState } from 'react';
import { useRetryableObservable } from 'react-use-observable';

import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { dateFormat } from 'src/helpers/date';
import { useDictionary } from 'src/hooks/useDictionary';
import productService from 'src/services/product';

const ProductPage = memo(() => {
  const [search, setSearch] = useState('');
  const [products, error, , retry] = useRetryableObservable(() => productService.list(search), [search]);
  const productDictionary = useDictionary({ type: 'Produto' });

  const filteredProducts = useMemo(
    () =>
      products?.map(product => ({
        ...product,
        dataHoraAlteracao: dateFormat(product.dataHoraAlteracao),
        id: product.idProduto
      })) ?? [],
    [products]
  );

  const onSearch = useCallback(search => setSearch(search), []);

  const loading = !products;

  return (
    <Container paper full>
      <Header title='Produtos' to='/product/create' showCreateButton onSearch={onSearch} />
      <div>
        <Table
          loading={loading}
          error={error}
          onRetry={retry}
          data={filteredProducts ?? []}
          perPage={4}
          columns={[
            {
              Header: productDictionary.getHeader('codigoBarras'),
              accessor: 'codBarras'
            },
            {
              Header: productDictionary.getHeader('custo'),
              accessor: 'custo'
            },
            {
              Header: productDictionary.getHeader('dataHoraalteracao'),
              accessor: 'dataHoraAlteracao'
            },
            {
              Header: productDictionary.getHeader('descricaoProduto'),
              accessor: 'descricaoProduto'
            },
            {
              Header: productDictionary.getHeader('estoqueMin'),
              accessor: 'estoqueMin'
            },
            {
              Header: productDictionary.getHeader('mercN1'),
              accessor: 'mercN1'
            },
            {
              Header: productDictionary.getHeader('mercN2'),
              accessor: 'mercN2'
            },
            {
              Header: productDictionary.getHeader('mercN3'),
              accessor: 'mercN3'
            },
            {
              Header: productDictionary.getHeader('mercN4'),
              accessor: 'mercN4'
            },
            {
              Header: productDictionary.getHeader('prazoValidade'),
              accessor: 'prazoValidade'
            },
            {
              Header: productDictionary.getHeader('usuarioAlteracao'),
              accessor: 'usuarioAlteracao'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default ProductPage;

import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route as RouteDom, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useObservable } from 'react-use-observable';

import Loading from 'src/components/Shared/Loading';
import AppLayout from 'src/layout/App';
import DefaultLayout from 'src/layout/Default';
import authService from 'src/services/auth';

interface IProps extends RouteProps {
  component: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, unknown>>;
  exact?: boolean;
  path?: string;
  isPrivate?: boolean;
  headerTitle?: string;
  ignoreSigned?: boolean;
}

const Route = memo<IProps>(({ component: Component, isPrivate = true, headerTitle, ignoreSigned, ...rest }) => {
  const [signed] = useObservable(() => {
    return authService.watchIsAuthenticated();
  }, []);

  const [doChangePassword] = useObservable(() => {
    return authService.watchDoChangePassword();
  }, [signed]);

  // const [modules] = useObservable(() => {
  //   return moduleService.observableResfresh().pipe(switchMap(refresh => moduleService.getModules(refresh)));
  // }, []);

  if (!signed && signed !== null && isPrivate && !ignoreSigned) {
    return <Redirect to='/login' />;
  }

  if (signed && !isPrivate && rest?.path === '/login') {
    return <Redirect to='/' />;
  }

  if (signed && !isPrivate && !ignoreSigned && doChangePassword) {
    return <Redirect to='/changePassword' />;
  }

  const Layout = signed && !doChangePassword ? AppLayout : DefaultLayout;
  const loading = signed === undefined || signed === null;

  const render = (props: RouteComponentProps) =>
    loading ? (
      <Loading />
    ) : (
      <Layout>
        <Helmet title={`${headerTitle ? `${headerTitle} | ` : ''}Varejo Ti9`} />
        <Component {...props} />
      </Layout>
    );

  // eslint-disable-next-line react/jsx-no-bind
  return <RouteDom {...rest} render={render} />;
});

export default Route;

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IModelStructure, IStructureListResponse } from 'src/interfaces/structure';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class StructureService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public list(search?: string): Observable<IStructureListResponse[]> {
    return this.apiService
      .get('/varejo/Estrutura/', {
        q: search,
        idEmpresa: 2,
        idLoja: 1
      })
      .pipe(
        map((result: { Status: IStructureListResponse[] }) =>
          typeof result?.Status === 'string' ? [] : result?.Status
        )
      );
  }

  public create(model: IModelStructure): Observable<void> {
    return this.storageService
      .get('authToken')
      .pipe(
        switchMap(values =>
          this.apiService.post('/varejo/Estrutura/', { ...model, idEmpresa: values?.company, idLoja: values?.store })
        )
      );
  }

  public update(model: IModelStructure): Observable<void> {
    return this.storageService
      .get('authToken')
      .pipe(
        switchMap(values =>
          this.apiService.put('/varejo/Estrutura/', [{ ...model, idEmpresa: values?.company, idLoja: values?.store }])
        )
      );
  }

  public delete(model: IModelStructure): Observable<void> {
    return this.storageService.get('authToken').pipe(
      switchMap(values =>
        this.apiService.delete('/varejo/Estrutura/', {
          codEstrutura: model.codEstrutura,
          idEmpresa: values?.company,
          idLoja: values?.store
        })
      )
    );
  }
}

const structureService = new StructureService(apiService, storageService);
export default structureService;

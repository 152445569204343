import { memo, useMemo } from 'react';
import { useRetryableObservable } from 'react-use-observable';

import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { useDictionary } from 'src/hooks/useDictionary';
import companyService from 'src/services/company';

const CompanyPage = memo(() => {
  const [companies, error, loading] = useRetryableObservable(() => companyService.list(), []);
  const companyDictionary = useDictionary({ type: 'Empresa' });
  const filteredCompanies = useMemo(() => companies?.status ?? [], [companies]);

  return (
    <Container paper full>
      <Header title='Empresas' to='/company/create' showCreateButton />
      <div>
        <Table
          loading={loading && !error}
          data={typeof filteredCompanies === 'string' ? [] : filteredCompanies}
          columns={[
            {
              Header: companyDictionary.getHeader('nmRazao'),
              accessor: 'nmRazao'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default CompanyPage;

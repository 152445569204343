import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IUpload, IUploadFileModel } from 'src/interfaces/import';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';

export class ImportService {
  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public uploadFile(model: IUploadFileModel): Observable<IUpload> {
    return this.storageService.get('authToken').pipe(
      switchMap(values => {
        const data = new FormData();
        if (model?.file[0].item) {
          data.append('file', model.file[0].item);
        }
        data.set('type', model.type);
        data.set('idEmpresa', values.company);
        data.set('idLoja', values.store);
        data.set('user', values.user);

        return this.apiService.post('/varejo/FileUpload/', data);
      })
    );
  }
}

const importService = new ImportService(apiService, storageService);
export default importService;

import { memo, useMemo } from 'react';
import { useCallbackObservable, useRetryableObservable } from 'react-use-observable';

import { makeStyles } from '@material-ui/core';
import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { useDictionary } from 'src/hooks/useDictionary';
import { IStoreUpdate } from 'src/interfaces/store';
import storeService from 'src/services/store';
import swal from 'sweetalert';

const StorePage = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      width: '100%'
    }
  }));
  const styles = useStyles();
  const [stores, , , retry] = useRetryableObservable(() => storeService.list(), []);
  const storeDictionary = useDictionary({ type: 'Loja' });

  const filteredStores = useMemo(() => stores?.map(store => ({ ...store, id: store.idLoja })) ?? [], [stores]);

  const [onDelete] = useCallbackObservable(
    (props: IStoreUpdate) => {
      return storeService.delete(props).pipe(
        tap(
          () => retry(),
          () => swal('Ops!', `Não foi possível deletar a loja ${props.nmLoja}`, 'error')
        )
      );
    },
    [stores]
  );

  const loading = !stores;

  return (
    <Container paper full>
      <Header title='Lojas' to='/store/create' showCreateButton />
      <div className={styles.content}>
        <Table
          loading={loading}
          data={filteredStores}
          onDelete={onDelete}
          columns={[
            {
              Header: storeDictionary.getHeader('nmLoja'),
              accessor: 'nmLoja'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default StorePage;

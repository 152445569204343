import { useCallback } from 'react';
import { useObservable } from 'react-use-observable';

import { map } from 'rxjs/operators';
import { ISetToken } from 'src/interfaces/token';
import storageService from 'src/services/storage';

interface IProps {
  type: string;
}

export function useDictionary({ type }: IProps) {
  const [dictionary] = useObservable(
    () =>
      storageService.get('authToken').pipe(map((values: { dictionary: ISetToken['dictDados'] }) => values.dictionary)),
    []
  );

  const screen = dictionary?.find(dados => {
    return dados.Tela === type;
  });

  const formatLabel = useCallback(
    label => {
      if (!label || label === '') return '';
      const labelSplited = label.split(' ');
      let labelFormated = '';
      for (let i = 0; i < labelSplited.length; i++) {
        labelFormated = `${labelFormated} ${
          labelSplited[i].charAt(0).toUpperCase() + labelSplited[i].slice(1).toLowerCase()
        }`;
      }

      return labelFormated;
    },
    [screen]
  );

  const getHeader = useCallback(
    (key: string) => {
      return formatLabel(screen?.values?.find(value => value?.type === key)?.label ?? '');
    },
    [screen]
  );

  return {
    values: screen?.values ?? null,
    getHeader
  };
}

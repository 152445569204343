import { memo, useMemo } from 'react';
import { useCallbackObservable, useRetryableObservable } from 'react-use-observable';

import { tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { IUpdatePermission } from 'src/interfaces/permission';
import permissionService from 'src/services/permission';
import swal from 'sweetalert';

const PermissionPage = memo(() => {
  const [permissions, , , retry] = useRetryableObservable(() => permissionService.list(), []);

  const filteredPermissions = useMemo(
    () =>
      permissions?.map(permission => ({ ...permission, id: `${permission.aplication}-${permission.module}` })) ?? [],
    [permissions]
  );
  const loading = !permissions;

  const [onDelete] = useCallbackObservable(
    (props: IUpdatePermission) => {
      return permissionService.delete(props).pipe(
        tap(
          () => retry(),
          () => swal('Ops!', 'Não foi possível deletar a permissão', 'error')
        )
      );
    },
    [permissions]
  );

  return (
    <Container paper full>
      <Header title='Permissões' to='/permission/create' showCreateButton />
      <div>
        <Table
          loading={loading}
          data={filteredPermissions}
          onDelete={onDelete}
          notClickable
          columns={[
            {
              Header: 'Aplicação',
              accessor: 'aplication'
            },
            {
              Header: 'Modulo',
              accessor: 'module'
            },
            {
              Header: 'Usuário',
              accessor: 'user'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default PermissionPage;

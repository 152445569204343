import { memo, useCallback, useMemo, useState } from 'react';
import { useRetryableObservable } from 'react-use-observable';

import Container from 'src/components/Shared/Container';
import Header from 'src/components/Shared/Header';
import Table from 'src/components/Shared/Table';
import { addDays } from 'src/helpers/date';
import { useDictionary } from 'src/hooks/useDictionary';
import userService from 'src/services/user';

const UserPage = memo(() => {
  const [search, setSearch] = useState('');
  const [users, , ,] = useRetryableObservable(() => userService.list(search), [search]);
  const userDictionary = useDictionary({ type: 'Usuario' });

  const onSearch = useCallback(search => setSearch(search), []);

  const filteredUsers = useMemo(
    () =>
      users?.map(user => ({
        ...user,
        dtNascimento: addDays(new Date(user.dtNascimento), 1, 'dd/MM/yyyy'),
        id: user.usuario
      })) ?? [],
    [users]
  );

  return (
    <Container paper full>
      <Header title='Usuários' to='/user/create' showCreateButton onSearch={onSearch} />
      <div>
        <Table
          data={filteredUsers}
          columns={[
            {
              Header: userDictionary.getHeader('nmUsuario'),
              accessor: 'nmUsuario'
            },
            {
              Header: userDictionary.getHeader('dsCargo'),
              accessor: 'dsCargo'
            },
            {
              Header: userDictionary.getHeader('dtNascimento'),
              accessor: 'dtNascimento'
            },
            {
              Header: userDictionary.getHeader('usuario'),
              accessor: 'usuario'
            }
          ]}
        />
      </div>
    </Container>
  );
});

export default UserPage;

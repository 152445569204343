import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class StorageService {
  public get<T = any>(key: string): Observable<T> {
    return of(true).pipe(
      map(() => localStorage.getItem(key)),
      map(data => (data ? JSON.parse(data) : null)),
      catchError(() => of(null))
    );
  }

  public set<T = any>(key: string, value: T): Observable<T> {
    return of(true).pipe(
      map(() => localStorage.setItem(key, JSON.stringify(value))),
      map(() => value),
      catchError(() => of(null))
    );
  }

  public remove<T = any>(key: string): Observable<T> {
    return of(true).pipe(
      map(() => localStorage.removeItem(key)),
      catchError(() => of(null))
    );
  }
}

const storageService = new StorageService();
export default storageService;

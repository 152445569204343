import { memo } from 'react';
import { SwitchProps } from 'react-router';

import { FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import { FormikInstance } from 'src/hooks/useFormikObservable';

interface IProps extends SwitchProps {
  name: string;
  formik?: FormikInstance;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  helpMessage?: string;
}

const SwitchField = memo<IProps>(({ name, formik, label, ...props }) => {
  const fieldMeta = formik?.getFieldMeta(name);

  const useStyles = makeStyles(() => ({
    container: {
      margin: '0.6rem 0',
      width: '100%'
    }
  }));
  const styles = useStyles();

  return (
    <FormControlLabel
      className={styles.container}
      control={
        <Switch
          {...props}
          color='primary'
          checked={fieldMeta.value}
          onChange={formik.handleChange}
          name={name}
          inputProps={{ 'aria-label': label }}
        />
      }
      label={label}
    />
  );
});

export default SwitchField;

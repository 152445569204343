import { memo, useMemo } from 'react';
import { useCallbackObservable, useObservable } from 'react-use-observable';

import { Button } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { map } from 'rxjs/operators';
import authService from 'src/services/auth';
import storageService from 'src/services/storage';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  user: {
    paddingTop: 5
  }
});
const UserStatus = memo(() => {
  const styles = useStyles();
  const [user] = useObservable(() => storageService.get('authToken').pipe(map(values => values?.user)), []);
  const [onLogout] = useCallbackObservable(() => authService.logout(), []);

  const userProcessado = useMemo(() => {
    if (!user) return '';
    const [first, second] = user?.split('.');
    return `${first[0].toUpperCase() + first.slice(1)} ${second[0].toUpperCase() + second.slice(1)}`;
  }, [user]);

  return (
    <div className={styles.container}>
      <div className={styles.user}>
        <span>
          Seja bem vindo <strong>{userProcessado}</strong>
        </span>
      </div>
      <Button color='inherit' aria-label='open drawer' onClick={onLogout} startIcon={<ExitToApp />}>
        Sair
      </Button>
    </div>
  );
});

export default UserStatus;

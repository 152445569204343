import { Fragment, memo } from 'react';

import { Button } from '@material-ui/core';

interface IProps {
  error: boolean;
  message?: string;
  onClick: () => void;
}

const ErrorMessage = memo<IProps>(({ error, message, onClick }) => {
  return (
    <Fragment>
      <Button color='primary' variant='text' disabled={error} onClick={onClick}>
        {message ? message : 'Tentar Novamente'}
      </Button>
    </Fragment>
  );
});

export default ErrorMessage;

import { memo, useCallback } from 'react';

import { FormControl, InputProps, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormikInstance } from 'src/hooks/useFormikObservable';

interface IProps extends InputProps {
  name: string;
  data: any[];
  formik?: FormikInstance;
  label?: string;
  value?: string;
  title?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  solo?: boolean;
  helpMessage?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    margin: '0.6rem 0',
    width: '100%'
  }
}));

const AutocompleteField = memo<IProps>(
  ({ name, data, label, value, title, variant, formik, solo, helpMessage, ...props }) => {
    const fieldMeta = formik?.getFieldMeta(name) ?? { touched: false, error: '', value };
    fieldMeta.value = value ?? fieldMeta.value;
    const hasError = formik?.submitCount > 0 && !!fieldMeta.error;
    const disabled = props.disabled || formik?.isSubmitting;

    const renderInput = useCallback(
      params => (
        <TextField
          {...params}
          fullWidth
          name={name}
          label={label}
          value={fieldMeta.value}
          variant={variant ?? 'outlined'}
          disabled={disabled}
          error={hasError}
          helperText={hasError ? fieldMeta.error : helpMessage}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
        />
      ),
      [fieldMeta]
    );

    const getOptionLabel = useCallback(option => option[title] ?? option?.title, []);
    const onChange = useCallback(e => formik.setFieldValue(name, e.target?.innerText ?? ''), []);

    const styles = useStyles();

    return (
      <FormControl className={styles.container}>
        <Autocomplete
          id={name}
          inputValue={fieldMeta.value}
          freeSolo={solo ?? true}
          blurOnSelect
          options={data ?? []}
          onChange={onChange}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput}
        />
      </FormControl>
    );
  }
);

export default AutocompleteField;

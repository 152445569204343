import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { IListUserStoresResponse, ILoginParams } from 'src/interfaces/auth';
import { IAuthToken } from 'src/interfaces/token';

import apiService, { ApiService } from './api';
import storageService, { StorageService } from './storage';
import tokenService, { TokenService } from './token';

export class AuthService {
  private signed$ = new BehaviorSubject(null);
  private changePassword$ = new BehaviorSubject(false);

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private storageService: StorageService
  ) {
    this.tokenService.getToken().subscribe(token => {
      this.signed$.next(token?.accessToken ? true : false);
      this.changePassword$.next(token?.changePassword);
      return null;
    });
  }

  public login(data: ILoginParams): Observable<{ changePassword: boolean; signed: boolean }> {
    return this.apiService
      .post('/varejo/login/', [
        {
          usuario: data.user,
          id_empresa: data.company,
          id_loja: data.store,
          senha: data.password
        }
      ])
      .pipe(
        map(result => {
          if (result?.Change_Passord || result?.Change_Password) {
            this.changePassword$.next(true);
          }

          this.changePassword$.next(false);
          return tokenService.setToken(result, data);
        }),
        map(() => ({
          changePassword: this.changePassword$.value,
          signed: this.signed$.value
        }))
      );
  }

  public logout(): Observable<void> {
    return of(true).pipe(
      tap(() => tokenService.removeToken()),
      tap(() => this.signed$.next(false)),
      tap(() => this.changePassword$.next(false)),
      map(() => {
        return null;
      })
    );
  }

  public listUserStores(user?: string): Observable<IListUserStoresResponse[]> {
    if (!user || user === '')
      return this.storageService
        .get('authToken')
        .pipe(switchMap(values => this.apiService.get('/varejo/lista_loja/', { usuario: values?.user })));

    return this.apiService.get('/varejo/lista_loja/', { usuario: user });
  }

  public changePassword({ newPassword }: { newPassword: string }) {
    return this.storageService.get<IAuthToken>('authToken').pipe(
      switchMap(values =>
        this.apiService.put('/varejo/change_password/', {
          idEmpresa: values?.company,
          idLoja: values?.store,
          usuario: values?.user,
          senha: newPassword
        })
      ),
      switchMap(() => this.storageService.get<IAuthToken>('authToken')),
      map(result => {
        this.storageService.set('authToken', { ...result, changePassword: false }).subscribe();
        this.changePassword$.next(false);

        return result;
      })
    );
  }

  public watchIsAuthenticated() {
    return this.signed$.asObservable();
  }

  public watchDoChangePassword() {
    return this.changePassword$.asObservable();
  }
}

const authService = new AuthService(apiService, tokenService, storageService);
export default authService;

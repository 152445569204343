import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useObservable } from 'react-use-observable';

import { Button, makeStyles } from '@material-ui/core';
import { map, tap } from 'rxjs/operators';
import Container from 'src/components/Shared/Container';
import SelectField from 'src/components/Shared/Fields/Select';
import TextField from 'src/components/Shared/Fields/Text';
import Header from 'src/components/Shared/Header';
import { useFormikObservable } from 'src/hooks/useFormikObservable';
import addressService from 'src/services/address';
import historyService from 'src/services/history';
import structureService from 'src/services/structure';
import swal from 'sweetalert';
import * as yup from 'yup';

const AddressCreate = memo(() => {
  const useStyles = makeStyles(() => ({
    content: {
      paddingLeft: '10rem'
    },
    paperContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      paddingTop: 0
    },
    inputContainer: {
      margin: '0.6rem 0',
      width: '100%'
    },
    formContainer: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: 10
    }
  }));
  const styles = useStyles();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        codEstrutura: yup.string().required('Informe a estrutura').nullable(),
        rua: yup.string().required('Informe a rua').nullable(),
        coluna: yup.string().required('Informe a coluna').nullable(),
        nivel: yup.string().required('Informe o nível').nullable(),
        vao: yup.string().required('Informe o vão').nullable(),
        endereco: yup.string().required('Informe o endereço').nullable(),
        capacidade: yup.string().required('Informe a capacidade').nullable(),
        estMinimo: yup.string().required('Informe a quantidade mínima').nullable()
      }),
    []
  );

  const formik = useFormikObservable({
    initialValues: {
      codEstrutura: null,
      rua: '',
      coluna: '',
      nivel: '',
      vao: '',
      endereco: '',
      capacidade: null,
      estMinimo: null
    },
    validationSchema,
    onSubmit: values =>
      addressService.create(values).pipe(
        tap(
          () => historyService.goBack(),
          () => swal('Ops!', 'Não foi possível criar este endereço', 'error')
        )
      )
  });

  const [structures] = useObservable(
    () =>
      structureService.list().pipe(
        map(structures => {
          const newStructureValues = structures?.map(structure => ({
            label: structure.descricao,
            value: structure.codEstrutura
          }));

          return newStructureValues;
        })
      ),
    []
  );

  return (
    <Container paper>
      <Header title='Novo Endereço' />
      <form onSubmit={formik.handleSubmit} className={styles.formContainer} noValidate>
        <SelectField name='codEstrutura' label='Estrutura' data={structures} formik={formik} />
        <TextField name='rua' label='Rua' formik={formik} />
        <TextField name='coluna' label='Coluna' formik={formik} />
        <TextField name='nivel' label='Nível' formik={formik} />
        <TextField name='vao' label='Vão' formik={formik} />
        <TextField name='endereco' label='Endereço' formik={formik} />
        <TextField name='capacidade' label='Capacidade' formik={formik} type='number' />
        <TextField name='estMinimo' label='Quantidade mínima' formik={formik} type='number' />

        <div>
          <Button
            to='/address'
            component={Link}
            color='primary'
            variant='text'
            disabled={formik.isSubmitting}
            className={styles.firstButton}
          >
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained' disabled={formik.isSubmitting}>
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
});

export default AddressCreate;
